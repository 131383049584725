import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

// router.beforeEach((to) => {
//   if (to.path === "/") {
//     window.location.replace("http://getre.al");
//   }
// });

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view)
  ]))
}
}

})