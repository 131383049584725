import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@authentic-labs/vue-component-library/style.css";
// import "@authentic-labs/vue-component-library/dist/vue-component-library.css"; //TODO FIX THIS
import "@authentic-labs/survey-builder/style.css";
// import "@authentic-labs/survey-builder/dist/survey-builder.css"; //TODO FIX THIS
import amplitude from "amplitude-js";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import { plugin, defaultConfig } from "@formkit/vue";
import "@formkit/themes/genesis";
import mitt from "mitt";
const emitter = mitt();

amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_KEY);

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://dfec0eea78c9463590bcde67eebeb782@o479985.ingest.sentry.io/5922289",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost",
        "content.staging.authenticlabs.com",
        "content.authenticlabs.com",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

app.use(plugin, defaultConfig);
app.config.globalProperties.emitter = emitter;
app.provide("emitter", emitter);
app.use(router);
app.mount("#app");
